import React, { useState } from 'react';
import './Policy.css';
import drop_arrow from '../Assets/circle-chevron-down-solid.svg'

// Policy sections data
const policies = [
  {
    title: "Terms of Service",
    content: `Our commitment is to provide a fair and competitive price for every product in our showroom. We are here to offer advice and assist you in finding the perfect product for your home. We procure our products at the right price, enabling us to sell them at the right price. Customer service is at the core of our business, and we are dedicated to ensuring your satisfaction with every project. Our extensive range includes competitively priced porcelain tiles, ceramic tiles, laminate, and wood flooring. We stock a wide selection of wall and floor products for bathrooms and kitchens. We take pride in offering the best range of ceramic and porcelain tiles in Bangor at the most competitive prices. Thank you for your business, and if you have the opportunity, we would greatly appreciate a Google or Facebook review!`
  },
  {
    title: "Return Policy",
    content: `Before installation, please thoroughly check all details to ensure that the color, quality, suitability, type, and intended area of the ordered and accepted products meet your requirements. It is essential to have a level, dry, suitable, and properly prepared surface. Please be advised that shades of the products can vary, and there may be slight variations between batches. We recommend allowing at least 10% extra for wastage. Additionally, colors and patterns may vary during the manufacturing process. If you find the products unsuitable or have any uncertainties, please refrain from installation and reach out to us. Refunds will be processed via the original payment method after the goods have been received by the "Tiles & Wood Floor Store." A 25% handling fee will be reducted from the refund amount. We strive to complete all refund processes within 14 working days.`
  },
  {
    title: "Damages / Incorrect Delivery",
    content: `In the event that you receive damaged or incorrect products, kindly notify Tiles & Wood Floor Store within 2 working days from the date of delivery or collection. We will make every effort to promptly replace the affected items.`
  },
  {
    title: "Unused Products",
    content: `Within 28 days from the date of delivery, we will issue a refund for any unopened boxes of stock products. However, please note that perishable items such as adhesives and grouts cannot be returned. Bespoke items or Items ordered from overseas can not be returned in accordance with Trading Standards. The customer is responsible for organizing the return of the goods to our main distribution warehouse.`
  },
  {
    title: "Policy",
    content: `We offer complimentary storage for your products as long as you require. However, we kindly request that you settle your balance upon arrival. Please note that collections cannot be facilitated on Sundays as we have limited staffing on that day. To avoid any inconvenience, we recommend refraining from booking a fitter until your products have arrived. Although our transport network usually provides a 48 hour delivery service, there may be rare occasions of unforeseen delays. If you have already scheduled a fitter, please inform our staff, and we will collaborate with you to meet your project deadline.`
  }
];

const Policy = () => {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (index) => {
    setOpenSections(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  return (
    <div className='policies'>
      {policies.map((policy, index) => (
        <div key={index} className="policy-section">
          <button className="policy-title" onClick={() => toggleSection(index)}>
            {policy.title}
            <img src={drop_arrow} className={`arrow-icon ${openSections[index] ? 'open' : ''}`} alt="Toggle visibility" />
          </button>
          {openSections[index] && (
            <div className="policy-content">{policy.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Policy;