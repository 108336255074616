import React, { useEffect, useState } from 'react';
import Item from '../Item/Item';
import './NewSale.css';
import TileMan from '../Assets/tileman.png';

const NewSale = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [onsale, setOnSale] = useState([]);
  const [countdown, setCountdown] = useState({ days: '', hours: '', minutes: '', seconds: '' });

  useEffect(() => {
      fetch(`${apiUrl}/saleitems`)
          .then((response) => response.json())
          .then((data) => {
              console.log('Fetched data:', data);
              setOnSale(data);
          })
          .catch(error => console.error('Error fetching data:', error));

      const targetDate = new Date('AUGUST 31, 2024 23:59:59').getTime();
      const updateTimer = () => {
          const now = new Date().getTime();
          const timeleft = targetDate - now;
          const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

          setCountdown({ days, hours, minutes, seconds });
      };
      updateTimer();
      const interval = setInterval(updateTimer, 1000);

      return () => clearInterval(interval);
  }, []);

  return (
      <div className='new-sales'>
          <div className="sale-header">
              <img src={TileMan} alt="Tile Man Promoting Sale" className="sale-image"/>
              <div className="sale-info">
                  <h1>AUGUST SALE</h1>
                  <p className="sale-tagline">Don't miss out on spectacular savings!</p>
                  <div className="countdown">
                    <div className="timer-item">
                     <div className="timer-box2">
                       {countdown.days}
                       <span className="timer-label-inline">days</span>
                     </div>
                   </div>
                   :
                   <div className="timer-item">
                     <div className="timer-box2">
                       {countdown.hours}
                       <span className="timer-label-inline">hours</span>
                     </div>
                   </div>
                   :
                   <div className="timer-item">
                    <div className="timer-box2">
                      {countdown.minutes}
                      <span className="timer-label-inline">mins</span>
                    </div>
                   </div>
                   :
                   <div className="timer-item">
                    <div className="timer-box2">
                      {countdown.seconds}
                      <span className="timer-label-inline">secs</span>
                    </div>
                   </div>
                  </div>
              </div>
          </div>
          <div className="new-item">
              {onsale.length > 0 ? (
                  onsale.map((item, i) => (
                      <Item 
                          key={item.id} 
                          id={item.id} 
                          name={item.name} 
                          size={item.size} 
                          image={item.images[0]} 
                          new_price={item.new_price} 
                          old_price={item.old_price} 
                      />
                  ))
              ) : (
                  <p>No items on sale currently.</p>
              )}
          </div>
      </div>
  );
};

export default NewSale;

