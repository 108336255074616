import React, { useState } from 'react';
import './Gallery.css';
import kitchen_island from '../Assets/GalleryFolder/kitchen_island_grey.webp';
import hallway_grey from '../Assets/GalleryFolder/hallway_grey.webp';
import WoodEffect_bathroom from '../Assets/GalleryFolder/Wood_Effect_bathroom.jpg';
import livingroom_walnut from '../Assets/GalleryFolder/livingroom_walnut.webp';
import bedroom_grey from '../Assets/GalleryFolder/bedroom_grey.webp';
import stairs_laminateCarpet from '../Assets/GalleryFolder/stairs_carpet_laminate.webp';
import kitchen_chair from '../Assets/GalleryFolder/kitchen_chairs.webp';
import hallway_herringbone from '../Assets/GalleryFolder/hallway_herringbone.webp';
import livingroom_beige from '../Assets/GalleryFolder/livingroom_beige.webp';
import bathroom_copper from '../Assets/GalleryFolder/bathroom_copper.webp';
import bedroom_grey2 from '../Assets/GalleryFolder/bedroom_grey2.webp';
import stairs_laminate from '../Assets/GalleryFolder/stairs_laminate.webp';
import kitchen_bodo from '../Assets/GalleryFolder/kitchen_bodo.webp';
import hallway_oak from '../Assets/GalleryFolder/hallway_oak.webp';
import bathroom_channel from '../Assets/GalleryFolder/bathroom_chennel.webp';
import livingroom_beige2 from '../Assets/GalleryFolder/livingroom_beige2.webp';
import bedroom_walnut from '../Assets/GalleryFolder/bedroom_walnut.jpg';
import stairs_herringbone from '../Assets/GalleryFolder/stairs_herringbone.jpg';
import kitchen_splashback from '../Assets/GalleryFolder/kitchen_splashback.webp';
import hallway_greige from '../Assets/GalleryFolder/hallway_greige.jpg';
import livingroom_deer from '../Assets/GalleryFolder/livingroom_deer.webp';
import bathroom_half from '../Assets/GalleryFolder/bathroom_half.webp';
import bedroom_walnut2 from '../Assets/GalleryFolder/bedroom_walnut2.jpg';
import stairs_turn from '../Assets/GalleryFolder/stairs_turn.jpg';
import kitchen_camden from '../Assets/GalleryFolder/kitchen_camden.webp';
import hallway_tile from '../Assets/GalleryFolder/hallway_tile.jpg';
import bathroom_howard from '../Assets/GalleryFolder/bathroom_howard.jpg';
import livingroom_walnut2 from '../Assets/GalleryFolder/livingroom_walnut2.jpg';
import bedroom_bay from '../Assets/GalleryFolder/bedroom_bay.jpg';
import stairs_carpet_laminate2 from '../Assets/GalleryFolder/stairs_carpet_laminate2.jpg';



const photoData = [
  { id: 1, category: 'Kitchens', url: kitchen_island, alt: 'Modern kitchen with grey 120x60cm porcelain tiles, featuring a central island and blue chairs'},
  { id: 2, category: 'Hallways', url: hallway_grey, alt: 'Grey laminate hallway with a vibrant red door, creating a striking entryway'},
  { id: 3, category: 'Bathrooms', url: WoodEffect_bathroom, alt: 'Wood-effect porcelain tiles on bathroom walls with a grey patterned tiled floor and a shower area'},
  { id: 4, category: 'Living room', url: livingroom_walnut, alt: 'Living room with walnut laminate flooring and a grey sofa, accented with an orange and blue painting and wallpaper'},
  { id: 5, category: 'Bedroom', url: bedroom_grey, alt: 'Kids\' bedroom with grey laminate flooring and a white bed, simple and clean design'},
  { id: 6, category: 'Stairs', url: stairs_laminateCarpet, alt: 'Staircase with grey carpet on treads and beige laminate on risers, modern home interior'},
  { id: 7, category: 'Kitchens', url: kitchen_chair, alt: 'Kitchen with grey Bodo 120x60cm floor tiles, featuring an island and sleek black chairs'},
  { id: 8, category: 'Hallways', url: hallway_herringbone, alt: 'Hallway with oak laminate in a herringbone pattern and a freestanding staircase, elegant and stylish'},
  { id: 9, category: 'Living room', url: livingroom_beige, alt: 'Rustic beige laminate flooring in a living room with a rustic grey couch, cozy and inviting'},
  { id: 10, category: 'Bathrooms', url: bathroom_copper, alt: 'Simplistic bathroom with large format 120x60cm porcelain tiles on the floor and small copper metallic effect tiles on the feature wall'},
  { id: 11, category: 'Bedroom', url: bedroom_grey2, alt: 'Bedroom with grey laminate flooring, featuring a white and yellow bed, bright and cheerful'},
  { id: 12, category: 'Stairs', url: stairs_laminate, alt: 'Small staircase with oak beige walnut laminate, compact and modern design'},
  { id: 13, category: 'Kitchens', url: kitchen_bodo, alt: ''},
  { id: 14, category: 'Hallways', url: hallway_oak, alt: ''},
  { id: 15, category: 'Bathrooms', url: bathroom_channel, alt: ''},
  { id: 16, category: 'Living room', url: livingroom_beige2, alt: ''},
  { id: 17, category: 'Bedroom', url: bedroom_walnut, alt: ''},
  { id: 18, category: 'Stairs', url: stairs_herringbone, alt: ''},
  { id: 19, category: 'Kitchens', url: kitchen_splashback, alt: ''},
  { id: 20, category: 'Hallways', url: hallway_greige, alt: ''},
  { id: 21, category: 'Bathrooms', url: bathroom_half, alt: ''},
  { id: 22, category: 'Living room', url: livingroom_deer, alt: ''},
  { id: 23, category: 'Bedroom', url: bedroom_walnut2, alt: ''},
  { id: 24, category: 'Stairs', url: stairs_turn, alt: ''},
  { id: 25, category: 'Kitchens', url: kitchen_camden, alt: ''},
  { id: 26, category: 'Hallways', url: hallway_tile, alt: ''},
  { id: 27, category: 'Bathrooms', url: bathroom_howard, alt: ''},
  { id: 28, category: 'Living room', url: livingroom_walnut2, alt: ''},
  { id: 29, category: 'Bedroom', url: bedroom_bay, alt: ''},
  { id: 30, category: 'Stairs', url: stairs_carpet_laminate2, alt: ''},

];


const Gallery = () => {
    const [activeCategory, setActiveCategory] = useState('');
  
    const filterPhotos = (category) => {
      setActiveCategory(category);
    };
  
    const filteredPhotos = photoData.filter(photo =>
      activeCategory === '' || photo.category === activeCategory
    );
  
    return (
      <div className='main-container'>
        <h2>Customer Photos</h2>
        <div className="filter-buttons">
          {['Kitchens', 'Hallways', 'Bathrooms', 'Living room', 'Bedroom', 'Stairs'].map(category => (
            <button key={category} onClick={() => filterPhotos(category)}>
              {category}
            </button>
          ))}
          <button onClick={() => filterPhotos('')}>All</button>
        </div>
        <div className='gallery'>
          {filteredPhotos.map(photo => (
            <div key={photo.id} className='photo-item'>
              <img src={photo.url} alt={photo.description} className="gallery-img"/>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Gallery;