import React from 'react';
import './Item.css';
import { Link } from 'react-router-dom';

const Item = (props) => {
  return (
    <div className="flex">
      <div className='item'>
        <Link to={`/product/${props.id}`}><img onClick={() => window.scrollTo(0,0)} src={props.image} alt="" /></Link>
        <p>{props.name} {props.size}</p>
        
        <div className="items-prices">
          <div className="item-price-new">
            £{props.new_price}m²
          </div>
          {props.old_price && (
            <div className="item-price-old">
              £{props.old_price}m²
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Item;