import React, { useContext, useRef, useEffect, useState } from 'react';
import './CartItems.css';
import { ShopContext } from '../../Context/ShopContext';
import remove_icon from '../Assets/cart_cross_icon.png';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const apiUrl = process.env.REACT_APP_API_URL || 'https://api.tileswoodfloorni.com';
const stripePromise = loadStripe('pk_live_51PRuSSRpzxWSKvXb4JahVKiNuJZaiHzVTZygSAiUe9LqADWgRh7zudusydvmXWbWUjWdkkxoUFILaJbSMM0dLMu600lCIFZhUT');

const CartItems = () => {
    const { all_product, cartItems, removeFromCart } = useContext(ShopContext);
    const [showCheckout, setShowCheckout] = useState(false);
    const [deliveryMethod, setDeliveryMethod] = useState(null);
    const [address, setAddress] = useState({
        postcode: '',
        street: '',
        city: '',
        country: 'Northern Ireland'
    });
    const [shippingCost, setShippingCost] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);
    const checkoutRef = useRef(null);

    let totalPrice = 0;
    let totalSquareMeters = 0;
    let totalBoxes = 0;
    let totalPallets = 0;

    Object.keys(cartItems).forEach(itemId => {
        const item = all_product.find(product => product.id.toString() === itemId);
        if (item) {
            const cartItem = cartItems[itemId];
            const price_per_box = item.new_price * item.box_size; // Calculate price per box
            const itemTotalPrice = price_per_box * cartItem.totalBoxes;
            totalPrice += itemTotalPrice;
            totalSquareMeters += cartItem.totalArea;
            totalBoxes += cartItem.totalBoxes;
            totalPallets += Math.ceil(cartItem.totalArea / item.pallet);
        }
    });

    const calculateShippingCost = (address, totalPallets) => {
        let costPerPallet = 0;

        switch (address.country.toUpperCase()) {
            case 'NORTHERN IRELAND':
            case 'IRELAND':
                costPerPallet = 85;
                break;
            case 'SCOTLAND':
            case 'ENGLAND':
            case 'WALES':
                costPerPallet = 130;
                break;
            default:
                alert('Shipping unavailable to this location.');
                return;
        }

        return costPerPallet * totalPallets;
    };

    const handleCheckout = async () => {
        if (deliveryMethod === 'shipping' && (!address.postcode || !address.street || !address.city || !address.country)) {
            setShowModal(true);
            return;
        }

        const stripe = await stripePromise;

        // Calculate shipping cost
        let calculatedShippingCost = 0;
        if (deliveryMethod === 'shipping') {
            calculatedShippingCost = calculateShippingCost(address, totalPallets);
            if (calculatedShippingCost === undefined) {
                return;
            }
            setShippingCost(calculatedShippingCost);
        }

        const cartItemsArray = Object.keys(cartItems)
            .map(itemId => {
                const item = all_product.find(product => product.id.toString() === itemId);
                if (item && cartItems[itemId].totalBoxes > 0) {
                    const price_per_box = item.new_price * item.box_size;
                    return {
                        id: item.id,
                        name: `${item.name} ${item.size}`,
                        image: item.images[0],
                        price_per_box, // Price per box
                        totalBoxes: cartItems[itemId].totalBoxes,
                    };
                }
                return null;
            })
            .filter(item => item !== null);

        if (deliveryMethod === 'shipping' && calculatedShippingCost > 0) {
            cartItemsArray.push({
                id: 'shipping',
                name: 'Shipping Cost',
                image: '',
                price_per_box: calculatedShippingCost, // Shipping cost per box (or per unit)
                totalBoxes: 1,
            });
        }

        const authToken = localStorage.getItem('auth-token');

        const response = await fetch(`${apiUrl}/create-checkout-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`, // Include token in the Authorization header
            },
            body: JSON.stringify({
                cartItems: cartItemsArray,
                shippingDetails: deliveryMethod === 'shipping' ? address : null,
            }),
        });

        if (response.ok) {
            const session = await response.json();

            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                console.error(result.error.message);
            }
        } else {
            const errorData = await response.json();
            console.error('Failed to create checkout session:', errorData.error);
        }
    };

    useEffect(() => {
        if (showCheckout) {
            setTimeout(() => {
                checkoutRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 300);  // Delay for the fade-in effect to kick in
        }
    }, [showCheckout]);

    return (
        <div className='cartItems'>
            <div className="formatMain">
                <p>Products</p>
                <p>Title</p>
                <p>Price</p>
                <p>Quantity</p>
                <p>Total Boxes</p>
                <p>Total</p>
                <p>Remove</p>
            </div>
            <hr />
            {Object.keys(cartItems).map((itemId) => {
                const item = all_product.find((product) => product.id.toString() === itemId);
                const cartItem = cartItems[itemId];
                console.log(item); // Log the found product

                if (item && cartItem.totalBoxes > 0) { // Check if totalBoxes is greater than 0
                    return (
                        <div key={itemId} className="format">
                            <Link to={`/product/${item.id}`} target="_blank" rel="noopener noreferrer">
                                <img src={item.images[0]} alt={item.name} className='cartIcon-productIcon' />
                            </Link>
                            <p>{item.name} {item.size}</p>
                            <p>£{item.new_price}m²</p>
                            <p>{cartItem.totalArea}m²</p>
                            <p>{cartItem.totalBoxes}</p>
                            <p>£{(item.new_price * cartItem.totalArea).toFixed(2)}</p>
                            <img src={remove_icon} onClick={() => removeFromCart(item.id)} alt="Remove Item" className='cart-remove-icon' />
                        </div>
                    );
                }

                return null;
            })}
            <div className="cartitems-down">
                <div className="cartitems-total">
                    <h2>Total: £{totalPrice.toFixed(2)}</h2>
                    <h2>Total m²: {totalSquareMeters.toFixed(2)}m²</h2>
                    <h2>Total Boxes: {totalBoxes}</h2>
                    {deliveryMethod === 'shipping' && <h2>Shipping Cost: £{shippingCost.toFixed(2)}</h2>}
                </div>
                <div className="checkout-button-container">
                    <button onClick={() => setShowDeliveryOptions(true)}>Continue to Checkout</button>
                </div>
            </div>

            {showDeliveryOptions && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Choose Delivery Method</h2>
                        <button onClick={() => { setDeliveryMethod('shipping'); setShowModal(true); setShowDeliveryOptions(false); }}>Shipping</button>
                        <button onClick={() => { setDeliveryMethod('collection'); setShowModal(true); setShowDeliveryOptions(false); }}>Collection</button>
                    </div>
                </div>
            )}

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        {deliveryMethod === 'shipping' ? (
                            <>
                                <h2>Enter Shipping Address</h2>
                                <input
                                    type="text"
                                    value={address.street}
                                    onChange={(e) => setAddress({ ...address, street: e.target.value })}
                                    placeholder="Street Address"
                                />
                                <input
                                    type="text"
                                    value={address.city}
                                    onChange={(e) => setAddress({ ...address, city: e.target.value })}
                                    placeholder="City"
                                />
                                <select
                                    value={address.country}
                                    onChange={(e) => setAddress({ ...address, country: e.target.value })}
                                >
                                    <option value="Northern Ireland">Northern Ireland</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Scotland">Scotland</option>
                                    <option value="England">England</option>
                                    <option value="Wales">Wales</option>
                                </select>
                                <input
                                    type="text"
                                    value={address.postcode}
                                    onChange={(e) => setAddress({ ...address, postcode: e.target.value })}
                                    placeholder="Postcode"
                                />
                                <button onClick={() => {
                                    if (address.postcode && address.street && address.city && address.country) {
                                        const calculatedShippingCost = calculateShippingCost(address, totalPallets);
                                        if (calculatedShippingCost !== undefined) {
                                            setShippingCost(calculatedShippingCost);
                                            setShowModal(false);
                                            handleCheckout();
                                        }
                                    }
                                }}>Submit</button>
                            </>
                        ) : (
                            <>
                                <h2>Collection Address</h2>
                                <p>1 Balloo Ave, Bangor, BT19 7QT</p>
                                <button onClick={() => {
                                    setShowModal(false);
                                    handleCheckout();
                                }}>Continue</button>
                            </>
                        )}
                        <p className="excludes-text">Excludes Scottish Highlands and Lowlands, read full <Link to="/policies">policy</Link>.</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CartItems;

