
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import {BrowserRouter,Routes, Route} from 'react-router-dom';
import ShopCategory from './Pages/ShopCategory';
import Home from './Pages/Home';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import LoginSignup from './Pages/LoginSignup';
import OnSale from './Pages/OnSale';
import OurStores from './Pages/OurStores';
import Footer from './Components/Footer/Footer';
import Policies from './Pages/Policies';
import Photos from './Pages/Photos';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Success from './Components/Success/Success';
import NotFound from './Components/NotFound/NotFound';
import RoomCategory from './Components/RoomCategory/RoomCategory'; 

function App() {
  return (
    <div>
      <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/tiles' element={<ShopCategory category="Tiles"/>}/>
        <Route path='/laminate' element={<ShopCategory category="Laminate"/>}/>
        <Route path='/real-wood' element={<ShopCategory category="Real Wood"/>}/>
        <Route path='/gallery' element={<Photos/>}/>
	<Route path="/room/:roomName" element={<RoomCategory />} /> 
        <Route path="product" element={<Product/>}>
          <Route path=':productId' element={<Product/>}/>
        </Route>
        <Route path='/cart' element={<Cart/>}/>
        <Route path='/login' element={<LoginSignup/>}/>
        <Route path='/sale' element={<OnSale/>}/>
        <Route path='/our-stores' element={<OurStores/>}/>
        <Route path='/policies' element={<Policies/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/success/:orderId' element={<Success/>}/>
        <Route path='*' element={<NotFound/>}/>
      </Routes>
      <Footer/>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
