import React, { useContext, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../Assets/newlogo2.svg';
import cart_icon from '../Assets/cart-shopping-solid.svg';
import Phone from '../Assets/phone-solid-white.svg';
import location from '../Assets/location-dot-solid.svg';
import { ShopContext } from '../../Context/ShopContext';
import dropdown from '../Assets/menu_burger.svg';
import SearchBar from '../SearchBar/SearchBar';

const Navbar = () => {
    const [menu, setMenu] = useState("home");
    const { getTotalCartItems } = useContext(ShopContext);
    const menuRef = useRef();
    const lowerNavRef = useRef();
    const navigate = useNavigate();

    // Modified dropdown_toggle function
    const dropdown_toggle = (shouldClose) => {
        if (shouldClose) {
            menuRef.current.classList.remove('nav-menu-visible');
        } else {
            menuRef.current.classList.toggle('nav-menu-visible');
        }
    };

    // Function to handle link clicks on mobile
    const handleLinkClick = (page) => {
        setMenu(page);
        dropdown_toggle(true); // Close the menu after clicking
    };

    const handleCartClick = () => {
        if (localStorage.getItem('auth-token')) {
            navigate('/cart');
        } else {
            navigate('/login');
        }
    };

    const roomCategories = [
        'Bathroom', 'Kitchen', 'Hall', 'Utility', 'Conservatory', 'Dining Room', 'Living Room', 'Study', 'Bedroom', 'Outdoor'
    ];

    return (
        <div className='navbar'>
            <div className="upper-nav">
                <div className="upper-left-nav">
                    <a href="tel:02891225400" className="phone-link">
                        <img style={{ verticalAlign: 'middle' }} src={Phone} alt="Phone icon" />
                        <span className='medium-bold'>CALL 02891225400</span>
                    </a>
                </div>
                <div onClick={() => { setMenu("Our Stores") }} className="upper-right-nav">
                    <Link className='upper-nav-bar-link' to='/our-stores'>
                        <img src={location} alt="Location icon" />
                        <p>Our Stores</p>
                    </Link>
                </div>
            </div>

            <div className="middle-nav">
                <div onClick={() => { setMenu("home") }} className="nav-logo">
                    <Link className='nav-bar-link' to='/'>
                        <img src={logo} alt="Tiles & Wood Floor Store Logo" />
                    </Link>
                </div>
                <img onClick={() => dropdown_toggle(false)} src={dropdown} className='nav-dropdown' alt="Dropdown icon" />
                <ul ref={menuRef} className="nav-menu">
                    <li onClick={() => handleLinkClick("home")}><Link className='nav-bar-link' to='/'>Home</Link>{menu === "home" ? <hr /> : null}</li>
                    <li onClick={() => handleLinkClick("tiles")}><Link className='nav-bar-link' to='/tiles'>Tiles</Link>{menu === "tiles" ? <hr /> : null}</li>
                    <li onClick={() => handleLinkClick("Laminate")}><Link className='nav-bar-link' to='/laminate'>Laminate</Link>{menu === "Laminate" ? <hr /> : null}</li>
                    <li onClick={() => handleLinkClick("Real Wood")}><Link className='nav-bar-link' to='/real-wood'>Real Wood</Link>{menu === "Real Wood" ? <hr /> : null}</li>
                    <li onClick={() => handleLinkClick("Gallery")}><Link className='nav-bar-link' to='/gallery'>Gallery</Link>{menu === "Gallery" ? <hr /> : null}</li>
                    <SearchBar />
                    {/* Room categories - only visible in mobile mode */}
                    <div className="mobile-room-categories">
                        {roomCategories.map((room, index) => (
                            <li key={index} onClick={() => handleLinkClick(room)}>
                                <Link className='nav-bar-link' to={`/room/${room.toLowerCase()}`}>
                                    {room}
                                </Link>
                                {menu === room ? <hr /> : null}
                            </li>
                        ))}
                    </div>
                </ul>
                <div className="nav-login-cart">
                    {localStorage.getItem('auth-token') ?
                        <button onClick={() => { localStorage.removeItem('auth-token'); window.location.replace('/') }}>Logout</button>
                        : <Link style={{ textDecoration: 'none' }} to='/login'><button>Login</button></Link>}
                    <div onClick={handleCartClick} style={{ cursor: 'pointer' }}>
                        <img src={cart_icon} alt="Shopping Cart Icon" />
                    </div>
                    <div className="nav-cart-count">{getTotalCartItems()}</div>
                </div>
            </div>

            {/* Lower Navbar for Room Categories (visible on larger screens) */}
            <div className="lower-nav" ref={lowerNavRef}>
                <ul className="room-nav-menu">
                    {roomCategories.map((room, index) => (
                        <li key={index} onClick={() => handleLinkClick(room)}>
                            <Link className='nav-bar-link' to={`/room/${room.toLowerCase()}`}>
                                {room}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Navbar;

