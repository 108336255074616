import React from 'react';
import './Hero.css';
import Hero_image from '../Assets/697.jpg';
import percentage from '../Assets/0-percent.png';
import floor from '../Assets/floor.png';
import tiles from '../Assets/tiles.png';
import checkmark from '../Assets/checkmark.png';
import { Link } from 'react-router-dom';
import useCountdown from '../Timer/Timer';

const Hero = () => {
  const countdown = useCountdown('AUGUST 31, 2024 23:59:59');

  return (
    <div>
      <Link className='hero-sale-link' to='/sale'>
        <div className='hero' style={{
          backgroundImage: `url(${Hero_image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
          <div className="hero-left">
            <h1>AUGUST <span className="c-red">SALE</span> <br /> NOW ON</h1>
            <p>Huge Discounts across all ranges!</p>
            <p>While Stocks Last</p>
            <button>VIEW MORE</button>
          </div>
          <div className="hero-right">
           <div className="countdown2">        
              <h3>TIMES RUNNING OUT</h3>
             <div className="timer-flex">
             <div className="timer-item">
                <div className="timer-box2">
                  {countdown.days}
                  <span className="timer-label-inline">days</span>
                </div>
              </div>
              :
              <div className="timer-item">
                <div className="timer-box2">
                  {countdown.hours}
                  <span className="timer-label-inline">hours</span>
                </div>
              </div>
              :
              <div className="timer-item">
                <div className="timer-box2">
                  {countdown.minutes}
                  <span className="timer-label-inline">mins</span>
                </div>
              </div>
              :
              <div className="timer-item">
                <div className="timer-box2">
                  {countdown.seconds}
                  <span className="timer-label-inline">secs</span>
                </div>
              </div>
             </div>
            </div>  
          </div>
        </div>
      </Link>
      <div className="lower-hero">
        <div className="features">
          <li>
            <img src={percentage} alt="" />
            <h2>Finance Available</h2>
          </li>
          <li>
            <img src={floor} alt="" />
            <h2>Over 300 floors in stock</h2>
          </li>
          <li>
            <img src={tiles} alt="" />
            <h2>Over 700 tiles in stock</h2>
          </li>
          <li>
            <img src={checkmark} alt="" />
            <h2>Quote match guarantee</h2>
          </li>
        </div>
      </div>
    </div>
  );
};

export default Hero;

