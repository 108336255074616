import React, { useState, useContext, useRef, useEffect } from 'react';
import { ShopContext } from '../../Context/ShopContext';
import { Link } from 'react-router-dom';
import './SearchBar.css';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showResults, setShowResults] = useState(false);
    const containerRef = useRef(null);
    const { all_product } = useContext(ShopContext);

    const filteredProducts = searchTerm.length > 0
        ? all_product.filter(product =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
          ).slice(0, 8) // Limit to top 8 results
        : [];

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setShowResults(event.target.value !== '');
    };

    const clearSearch = () => {
        setSearchTerm('');
        setShowResults(false);
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            clearSearch();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="search-bar-container" ref={containerRef}>
            <input
                type="text"
                placeholder="Search products..."
                onChange={handleSearchChange}
                className="search-bar-input"
                value={searchTerm}
            />
            <div className={`search-results-container ${showResults ? '' : 'hide-search-results'}`}>
                {filteredProducts.map(product => (
                    <Link
                        to={`/product/${product.id}`}
                        className="search-result-item"
                        key={product.id}
                        onClick={clearSearch} // Clear search when clicking on an item
                    >
                        <img src={product.images[0]} alt={product.name} className="search-result-img" />
                        <div className="search-result-text">
                            {product.name} {product.size}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default SearchBar;

