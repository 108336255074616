import React, { useContext, useState, useEffect } from 'react';
import './ProductDisplay.css';
import PriceCalculator from '../MeasurementForm/MeasurementForm';
import { ShopContext } from '../../Context/ShopContext';

const ProductDisplay = (props) => {
    const { product } = props;
    const { addToCart } = useContext(ShopContext);

    // State to manage the main displayed image
    const [mainImage, setMainImage] = useState(product.images[0]);

    // Update mainImage whenever the product changes
    useEffect(() => {
        setMainImage(product.images[0]);
    }, [product]);

    // Wrap the single product in an array to match PriceCalculator's expected props
    const productForCalculator = [product].map(p => ({
        ...p,
        id: p.id, // Ensure the product structure matches what PriceCalculator expects
    }));

    return (
        <div className='productDisplay'>
            <div className="productDisplay-left">
                <div className="productDisplay-imgList">
                    {product.images.map((img, index) => (
                        <img
                            key={index}
                            src={img}
                            alt={`thumbnail-${index}`}
                            onClick={() => setMainImage(img)}
                            className={mainImage === img ? 'active' : ''}
                        />
                    ))}
                </div>
                <div className="productDisplay-img">
                    <img className='productDisplay-imgMain' src={mainImage} alt="main" />
                    <h2>Order a Free <br /><span>Sample</span></h2>
                </div>
            </div>
            <div className="productDisplay-middle">
                <h1>{product.name} {product.size}</h1>
                <div className="priceWrapper">
                    <div className="productDisplay-priceNew">
                        £{product.new_price}m²
                    </div>
                    <div className="productDisplay-priceOld">
                        <span>RRP </span>£{product.old_price}m²
                    </div>
                </div>
                <hr className='hr-style' />
                <h3 className='details-header'>Details:</h3>
                <div className="product-info">
                    <div className="product-info-left">
                        <p><span> Box Size:</span> {product.box_size}m²</p>
                        <p><span>Format:</span> {product.size}cm</p>
                        <p><span>Finish:</span> {product.finish}</p>
                        <p><span>Suitable for:</span> {product.sub_category}</p>
                    </div>
                    <div className="product-info-right">
                        {product.category === 'Tiles' ? (
                            <>
                                <p><span>Profile:</span> {product.rectified}</p>
                                <p><span>Material:</span> {product.material}</p>
                            </>
                        ) : (
                            <>
                                <p><span>AC Rating:</span> {product.ac_rating}</p>
                                <p><span>Warranty:</span> {product.warranty} Years</p>
                            </>
                        )}
                        <p><span>Thickness:</span> {product.thickness}mm</p>
                        <p><span>Available:</span> In Stock</p>
                    </div>
                </div>
                <h4>Description:</h4>
                <div className="productDisplay-desc">
                    {product.desc}
                </div>
                <h5>Tags:</h5>
                <div className="product-tags">
                    {product.category}, {product.sub_category}, {product.finish}, {product.r_rating},
                </div>
            </div>
            <div className="productDisplay-right">
                <div className="priceCalculator">
                    <PriceCalculator products={productForCalculator} />
                </div>
            </div>
        </div>
    );
}

export default ProductDisplay;

