import { useState, useEffect } from 'react';

const useCountdown = (targetDate) => {
  const [countdown, setCountdown] = useState({ days: '', hours: '', minutes: '', seconds: '' });

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date().getTime();
      const timeleft = new Date(targetDate).getTime() - now;
      const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    };

    updateTimer();
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return countdown;
};

export default useCountdown;
