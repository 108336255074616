import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Measurement.css';
import { ShopContext } from '../../Context/ShopContext';

function MeasurementForm({ products }) {
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [width, setWidth] = useState('');
  const [length, setLength] = useState('');
  const [totalSquareMeters, setTotalSquareMeters] = useState('');
  const [useTotalSquareMeters, setUseTotalSquareMeters] = useState(true);
  const [unit, setUnit] = useState('m2'); // m2 for square meters, ft2 for square feet
  const { addToCart } = useContext(ShopContext);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for managing modal visibility

  // Synchronize selectedProductId with the new products list
  useEffect(() => {
    if (products.length > 0) {
      const newProductId = products[0].id;
      setSelectedProductId(newProductId);
    }
  }, [products]);

  // Update selectedProduct whenever selectedProductId or products change
  useEffect(() => {
    if (selectedProductId !== null) {
      const product = products.find(p => p.id === selectedProductId);
      setSelectedProduct(product);
    }
  }, [selectedProductId, products]);

  // Logging to debug
  console.log('Products:', products);
  console.log('Selected Product ID:', selectedProductId);
  console.log('Selected Product:', selectedProduct);

  // Ensure selectedProduct is defined and has box_size before proceeding
  if (!selectedProduct || !selectedProduct.box_size) {
    return <div>Loading product details...</div>; // Show a loading state or an appropriate message
  }

  // Conversion constants
  const squareMetersToSquareFeet = 10.7639;
  const squareFeetToSquareMeters = 1 / squareMetersToSquareFeet;

  const convertArea = (area, toUnit) => {
    return toUnit === 'ft2' ? area * squareMetersToSquareFeet : area * squareFeetToSquareMeters;
  };

  const calculateTotalBoxes = () => {
    let area = useTotalSquareMeters ? parseFloat(totalSquareMeters) : width * length;
    if (unit === 'ft2') {
      area = convertArea(area, 'm2'); // Convert to square meters for calculation
    }
    const boxesNeeded = Math.ceil(area / selectedProduct.box_size);
    return boxesNeeded || 1; // Ensure at least 1 box is considered by default
  };

  const boxesNeeded = calculateTotalBoxes(); // Store the result to use it for rendering

  const calculateTotalPrice = () => {
    return (boxesNeeded * selectedProduct.box_size * selectedProduct.new_price).toFixed(2);
  };

  const calculateTotalArea = () => {
    let totalArea = boxesNeeded * selectedProduct.box_size;
    if (unit === 'ft2') {
      totalArea = convertArea(totalArea, 'ft2'); // Convert back to square feet if needed
    }
    return totalArea.toFixed(2);
  };

  const handleAddToCart = () => {
    if (!localStorage.getItem('auth-token')) {
      navigate('/login');
      return;
    }

    // Ensure these calculations are correctly fetching and calculating values
    const totalBoxes = calculateTotalBoxes();
    const totalPrice = calculateTotalPrice();
    const totalArea = calculateTotalArea();

    // Debugging: Log values to ensure they are as expected
    console.log(
      `Adding to cart: Product ID: ${selectedProductId}, Total Boxes: ${totalBoxes}, Total Area: ${totalArea}, Total Price: ${totalPrice}`
    );

    // Correctly call addToCart with all necessary arguments
    addToCart(selectedProductId, totalBoxes, totalArea, totalPrice);
    setIsModalOpen(true); // Open the modal after adding to cart
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleViewCart = () => {
    navigate('/cart');
  };

  return (
    <div className='pad'>
      <select
        onChange={(e) => setSelectedProductId(Number(e.target.value))}
        value={selectedProductId}
      >
        {products.map((product) => (
          <option key={product.id} value={product.id}>
            {product.name}
          </option>
        ))}
      </select>

      <div className='flex'>
        <h3>Price your area</h3>
        <div className='style'>
          <label className={unit === 'm2' ? 'selected-unit' : 'unselected-unit'}>
            <input
              type='radio'
              name='unitType'
              checked={unit === 'm2'}
              onChange={() => setUnit('m2')}
            />
            m²
          </label>
          <label className={unit === 'ft2' ? 'selected-unit' : 'unselected-unit'}>
            <input
              type='radio'
              name='unitType'
              checked={unit === 'ft2'}
              onChange={() => setUnit('ft2')}
            />
            ft²
          </label>
        </div>
      </div>

      <div className='style2'>
        <label
          className={useTotalSquareMeters ? 'selected-unit' : 'unselected-unit'}
          id='total-area'
        >
          <input
            type='radio'
            name='inputType'
            checked={useTotalSquareMeters}
            onChange={() => setUseTotalSquareMeters(true)}
          />
          Total Area
        </label>
        <label className={!useTotalSquareMeters ? 'selected-unit' : 'unselected-unit'}>
          <input
            type='radio'
            name='inputType'
            checked={!useTotalSquareMeters}
            onChange={() => setUseTotalSquareMeters(false)}
          />
          W x L
        </label>
      </div>
      <div className='userInput-style'>
        {useTotalSquareMeters ? (
          <input
            type='number'
            value={totalSquareMeters}
            placeholder={`Total Area (${unit})`}
            onChange={(e) => setTotalSquareMeters(e.target.value)}
          />
        ) : (
          <>
            <input
              type='number'
              value={width}
              placeholder={`Width (${unit})`}
              onChange={(e) => setWidth(e.target.value)}
            />
            <p>x</p>
            <input
              type='number'
              value={length}
              placeholder={`Length (${unit})`}
              onChange={(e) => setLength(e.target.value)}
            />
          </>
        )}
      </div>
      <div className='flex'>
        <div className='coverageDisplay'>
          <p className='infoDisplay'>
            {boxesNeeded} {boxesNeeded === 1 ? 'Box' : 'Boxes'}
          </p>
          <p className='font12'>
            will cover: {calculateTotalArea()} {unit}
          </p>
        </div>
        <div className='priceDisplay'>
          <p className='infoDisplay'>£{calculateTotalPrice()}</p>
          <p className='font12'>inc VAT</p>
        </div>
      </div>
      <div className='cart-button'>
        <button onClick={handleAddToCart}>ADD TO CART</button>
      </div>

      {isModalOpen && (
        <div className='modal'>
          <div className='modal-content'>
            <h2>Would you like to</h2>
            <div className='modal-buttons'>
              <button onClick={handleViewCart}>View Cart</button>
              <button onClick={closeModal}>Continue Shopping</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MeasurementForm;

