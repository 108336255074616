import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Success.css';

const Success = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      console.log('Fetching order details for orderId:', orderId);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/order/${orderId}`);
        if (!response.ok) {
          throw new Error('Order not found');
        }
        const data = await response.json();
        console.log('Order details fetched:', data);
        setOrderDetails(data);
      } catch (error) {
        console.error('Error fetching order details:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (orderId) {
      fetchOrderDetails();
    } else {
      setError('Order ID is missing');
      setLoading(false);
    }
  }, [orderId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="success-container">
      <h1>Thank You for Your Order!</h1>
      <p>We appreciate your business! Below are your order details:</p>

      <div className="order-details">
        <h2>Order Details</h2>
        <p><strong>Email:</strong> {orderDetails.email}</p>
        {orderDetails.shippingDetails && (
          <>
            <p><strong>Shipping Address:</strong></p>
            <p>{orderDetails.shippingDetails.street}</p>
            <p>{orderDetails.shippingDetails.city}, {orderDetails.shippingDetails.postcode}</p>
            <p>{orderDetails.shippingDetails.country}</p>
          </>
        )}

        <h3>Items Ordered:</h3>
        <ul>
          {orderDetails.items.map((item, index) => (
            <li key={index}>
              {item.name} - {item.quantity} x £{item.price.toFixed(2)}
            </li>
          ))}
        </ul>
        <p><strong>Total:</strong> £{orderDetails.total.toFixed(2)}</p>
      </div>
    </div>
  );
};

export default Success;

